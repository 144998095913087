/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react'

function Icon(props): JSX.Element {
  return (
    <svg id="Adobe_XD_CC_icon_svg__Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 240 234" xmlSpace="preserve" {...props}>
      <style>{'.Adobe_XD_CC_icon_svg__st1{fill:#ff61f6}'}</style>
      <g id="Adobe_XD_CC_icon_svg__Layer_2_1_">
        <g id="Adobe_XD_CC_icon_svg__Surfaces">
          <g id="Adobe_XD_CC_icon_svg__UI_UX_Surface">
            <path
              d="M42.5 0h155C221 0 240 19 240 42.5v149c0 23.5-19 42.5-42.5 42.5h-155C19 234 0 215 0 191.5v-149C0 19 19 0 42.5 0z"
              fill="#470137"
              id="Adobe_XD_CC_icon_svg__Outline_no_shadow"
            />
          </g>
        </g>
        <g id="Adobe_XD_CC_icon_svg__Outlined_Mnemonics_Logos">
          <g id="Adobe_XD_CC_icon_svg__Xd">
            <path
              className="Adobe_XD_CC_icon_svg__st1"
              d="M126.2 61.5l-30 49.5 32 52.5c.2.4.3.8.2 1.2-.1.4-.5.1-1.1.2h-22.9c-1.6 0-2.7-.1-3.4-1.1-2.1-4.2-4.3-8.3-6.4-12.5-2.1-4.1-4.4-8.3-6.8-12.6-2.4-4.3-4.8-8.6-7.2-13h-.2c-2.1 4.3-4.4 8.6-6.7 12.9-2.3 4.3-4.6 8.6-6.8 12.8-2.3 4.2-4.6 8.5-6.9 12.6-.4 1-1.2 1.1-2.3 1.1h-22c-.4 0-.7.2-.7-.3-.1-.4 0-.8.2-1.1l31.1-51L36 61.4c-.3-.4-.4-.8-.2-1 .2-.3.6-.4 1-.4h22.7c.5 0 1 .1 1.4.2.4.2.7.5 1 .9 1.9 4.3 4.1 8.6 6.4 12.9 2.4 4.3 4.7 8.5 7.2 12.7 2.4 4.2 4.6 8.4 6.7 12.7h.2c2.1-4.4 4.3-8.7 6.5-12.9 2.2-4.2 4.5-8.4 6.8-12.6 2.3-4.2 4.5-8.5 6.7-12.6.1-.4.3-.8.6-1 .4-.2.8-.3 1.3-.2h21.1c.5-.1 1 .2 1.1.7.1.1-.1.5-.3.7zM172.4 167c-7.4.1-14.8-1.4-21.5-4.5-6.3-2.9-11.5-7.7-15.1-13.6-3.7-6.1-5.5-13.7-5.5-22.8-.1-7.4 1.8-14.7 5.5-21.1 3.8-6.5 9.3-11.9 15.9-15.5 7-3.9 15.4-5.8 25.3-5.8.5 0 1.2 0 2.1.1.9.1 1.9.1 3.1.2V52.4c0-.7.3-1.1 1-1.1h20.3c.5-.1.9.3 1 .7v95.4c0 1.8.1 3.8.2 6 .2 2.1.3 4.1.4 5.8 0 .7-.3 1.3-1 1.6-5.2 2.2-10.7 3.8-16.3 4.8-5.1.9-10.2 1.4-15.4 1.4zm9.8-20v-44c-.9-.2-1.8-.4-2.7-.5-1.1-.1-2.2-.2-3.3-.2-3.9 0-7.8.8-11.3 2.6-3.4 1.7-6.3 4.2-8.5 7.4s-3.3 7.5-3.3 12.7c-.1 3.5.5 7 1.7 10.3 1 2.7 2.5 5.1 4.5 7.1 1.9 1.8 4.2 3.2 6.8 4 2.7.9 5.5 1.3 8.3 1.3 1.5 0 2.9-.1 4.2-.2 1.3.1 2.4-.1 3.6-.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default { Icon }
