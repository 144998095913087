/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, PageHeader, Descriptions, Card, Button, Tag, Icon, Breadcrumb } from 'antd'
import { useRouteMatch, Link } from 'react-router-dom'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import PrettyBytes from 'react-pretty-bytes'
import videojs from 'video.js'
import { TBreadcrumbItemProps } from '@antegma/accallio-cloud-react-components/lib/app/util/appRoutes'
import contentSourceApi from '~/services/ContentSource'
import actions, { loadState } from '~/redux/content/items/details/actions'
import tokenApi from '~/services/Token'
import VideoPlayer from '~/components/videoplayer'
import styles from './styles.module.scss'
import AdobePhotoshop from '~/components/icons/AdobePhotoshop'
import AdobeIllustrator from '~/components/icons/AdobeIllustrator'
import AdobeStock from '~/components/icons/AdobeStock'
import AdobeXD from '~/components/icons/AdobeXD'
import { ISagas } from '~/redux/interface'

const LibraryElementDetails: React.FunctionComponent = () => {
  const route = useRouteMatch<{ libraryId: string; contentId: string }>()
  const dispatch = useDispatch()
  const ctx = useContext(ApplicationContext)
  const contentItemState = useSelector((state: ISagas) => state.contentItem)
  const oidc = useSelector((state: TDefaultSagas) => state.oidc)

  const options = { headers: { Authorization: `Bearer ${oidc.user?.access_token}` } }

  let hootsuiteUserId: string
  if (ctx.extraQueryParams !== undefined) {
    hootsuiteUserId = ctx.extraQueryParams.hootsuiteUserId
  }

  const {
    params: { libraryId, contentId },
  } = route

  useEffect(() => {
    dispatch({
      type: actions.LOAD_STATE,
      payload: {
        libraryId,
        contentId,
      },
    })
  }, [dispatch])

  const attachFileToMessage = (url: string, name: string, extension: string): void => {
    const directUpload = false

    let dateTime = Date.now()
    let timestamp = Math.floor(dateTime / 1000)
    console.log(`hootsuite user id for attach file to message: ${hootsuiteUserId}`)
    let input = `${hootsuiteUserId}${timestamp}${url}`

    tokenApi.generateToken(input).then((tokenResponse) => {
      let token = tokenResponse.data

      window['hsp']['attachFileToMessageV2']({
        url,
        name,
        extension,
        timestamp,
        token,
      })
    })
  }

  const openComposer = (): void => {
    let contentItemId = ''

    if (contentItemState.contentItem !== undefined && contentItemState.contentItem.id !== undefined) {
      if (contentItemState.contentItem.mediaType === 'Video') {
        contentSourceApi.uploadContentItemToHootsuite(contentItemState.contentItem.id).then((response) => {
          let hootsuiteMediaId = response.data
          let dateTime = Date.now()
          let timestamp = Math.floor(dateTime / 1000)
          let userId = hootsuiteUserId
          console.log(`hootsuite user id for attach to media: ${hootsuiteUserId}`)
          let input = `${userId}${timestamp}${hootsuiteMediaId}`

          tokenApi.generateToken(input).then((tokenResponse) => {
            let token = tokenResponse.data

            window['hsp']['attachMediaV2']({
              mediaId: hootsuiteMediaId,
              timestamp,
              token,
            })
          })
        })
      } else if (contentItemState.contentItem.mediaType === 'Image') {
        let url = contentItemState.contentItem.urls?.original

        let fileNameParts = contentItemState.contentItem.downloadableFileName?.split('.')
        let extension = ''
        if (fileNameParts !== undefined) {
          extension = fileNameParts[fileNameParts.length - 1]
        }

        if (url !== undefined && contentItemState.contentItem.downloadableFileName !== undefined) {
          attachFileToMessage(url, contentItemState.contentItem.downloadableFileName, extension)
        }
      }
    }
  }

  function Preview(): JSX.Element {
    if (contentItemState.contentItem?.mediaType === 'Image') {
      return (
        <div className={styles.image_preview_container}>
          <img
            className={styles.image_preview_image}
            src={contentItemState.contentItem?.urls?.preview}
            alt={`Preview of ${contentItemState.contentItem.name}`}
          />
        </div>
      )
    }

    let source = ''
    let mimeType = ''

    if (
      contentItemState.contentItem !== undefined &&
      contentItemState.contentItem?.urls !== undefined &&
      contentItemState.contentItem?.urls.play !== undefined &&
      contentItemState.contentItem?.mimeType !== undefined
    ) {
      source = contentItemState.contentItem?.urls?.play
      mimeType = contentItemState.contentItem?.mimeType
    }

    let videoJsOptions: videojs.PlayerOptions = {
      autoplay: false,
      preload: 'none',
      inactivityTimeout: 60 * 1000,
      poster: contentItemState.contentItem?.urls?.preview,
      controls: true,
      fluid: true,
      sources: [{ src: source, type: mimeType }],
    }

    return <VideoPlayer options={videoJsOptions} />
  }

  function MimeType(): JSX.Element {
    if (contentItemState.contentItem?.mimeType === 'application/photoshop') {
      return (
        <>
          <Icon component={AdobePhotoshop.Icon} /> Adobe Photoshop
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'application/illustrator') {
      return (
        <>
          <Icon component={AdobeIllustrator.Icon} /> Adobe Illustrator
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'application/vnd.adobe.library.link+dcx') {
      return (
        <>
          <Icon component={AdobeStock.Icon} /> Adobe Stock
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'application/adobexd') {
      return (
        <>
          <Icon component={AdobeXD.Icon} /> Adobe XD
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'image/jpeg') {
      return (
        <>
          <Icon type="file-jpg" /> JPEG
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'image/gif') {
      return (
        <>
          <Icon type="file-image" /> GIF
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'image/png') {
      return (
        <>
          <Icon type="file-image" /> PNG
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'image/tiff') {
      return (
        <>
          <Icon type="file-image" /> TIFF
        </>
      )
    }
    if (contentItemState.contentItem?.mimeType === 'video/mp4') {
      return (
        <>
          <Icon type="file-video" /> MP4
        </>
      )
    }
    return <>{contentItemState.contentItem?.mimeType}</>
  }

  return contentItemState.contentItem ? (
    <PageHeader title={`${contentItemState.contentItem.name}`}>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={18}>
          {contentItemState.contentItem !== undefined ? <Preview /> : <></>}
        </Col>
        <Col xs={24} lg={6}>
          <Button className={styles.open_composer_btn} type="primary" shape="round" size="large" icon="upload" onClick={openComposer}>
            Open in Composer
          </Button>
          <Card title="Information" bordered={false}>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Name">{contentItemState.contentItem.name}</Descriptions.Item>
              <Descriptions.Item label="Description">{contentItemState.contentItem.description}</Descriptions.Item>
              <Descriptions.Item label="Created">{contentItemState.contentItem.created}</Descriptions.Item>
              <Descriptions.Item label="Last Modified">{contentItemState.contentItem.lastUpdated}</Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title="Metadata" bordered={false}>
            <Descriptions size="small" column={1}>
              <Descriptions.Item label="Type">
                <MimeType />
              </Descriptions.Item>
              <Descriptions.Item label="Width">{contentItemState.contentItem.width}</Descriptions.Item>
              <Descriptions.Item label="Height">{contentItemState.contentItem.height}</Descriptions.Item>
              {contentItemState.contentItem.size !== undefined ? (
                <Descriptions.Item label="Size">
                  <PrettyBytes bytes={contentItemState.contentItem.size} />
                </Descriptions.Item>
              ) : (
                <></>
              )}
              {contentItemState.contentItem.duration !== undefined ? (
                <Descriptions.Item label="Duration">{contentItemState.contentItem.duration / 1000}</Descriptions.Item>
              ) : (
                <></>
              )}
            </Descriptions>
          </Card>
          {contentItemState.contentItem?.license !== undefined ? (
            <Card title="License" bordered={false}>
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Type">{contentItemState.contentItem?.license?.name}</Descriptions.Item>
                <Descriptions.Item label="Licensor">{contentItemState.contentItem?.license?.licensor}</Descriptions.Item>
              </Descriptions>
            </Card>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </PageHeader>
  ) : (
    <Loader />
  )
}

const LibraryElementDetailsBreadcrumbItem: React.FunctionComponent<TBreadcrumbItemProps> = (props: TBreadcrumbItemProps) => {
  const { page, active } = props
  const route = useRouteMatch<{ libraryId: string; contentId: string }>()
  const dispatch = useDispatch()
  const contentItemState = useSelector((state: ISagas) => state.contentItem)
  const libraryElementsState = useSelector((state: ISagas) => state.libraryElements)

  const {
    params: { libraryId, contentId },
  } = route

  const getBreadcrumbPath = (path: string): string => {
    return path.replace(':libraryId', libraryElementsState.libraryId).replace(':contentId', contentItemState.contentId)
  }

  useEffect(() => {
    dispatch({
      type: actions.GET_STATE,
    })
  }, [dispatch])
  return active ? (
    <Breadcrumb.Item key="breadcrumb-ContentDetailView">{contentItemState.contentItem?.name}</Breadcrumb.Item>
  ) : (
    <Breadcrumb.Item key="breadcrumb-ContentDetailView">
      <Link to={getBreadcrumbPath(page.routeProps.path as string)}>{contentItemState.contentItem?.name}</Link>
    </Breadcrumb.Item>
  )
}

export default {
  name: 'LibraryElementDetails',
  routeProps: {
    path: '/:contentId',
    component: LibraryElementDetails,
  },
  breadcrumbProps: {
    component: LibraryElementDetailsBreadcrumbItem,
  },
}
