import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TLibraryElementsState = {
  libraryId: string
  library?: ContentItem
  items: ContentItem[]
  page: number
  pageSize: number
  total: number
  loading: boolean
  query: string
}
const initialState = {
  libraryId: '',
  library: undefined,
  items: [],
  loading: false,
  page: 1,
  pageSize: 10,
  total: 0,
  query: '',
}
export default function collectionReducer(state: TLibraryElementsState = initialState, action: AnyAction): TLibraryElementsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
