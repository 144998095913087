import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TContentItemState = {
  contentItem?: ContentItem
  contentId: string
  loading: boolean
}
const initialState = {
  contentItem: undefined,
  contentId: '',
  loading: false,
}
export default function collectionReducer(state: TContentItemState = initialState, action: AnyAction): TContentItemState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
