import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TLibrariesState = {
  items: ContentItem[]
  page: number
  pageSize: number
  total: number
  query: string
  loading: boolean
}
const initialState = {
  items: [],
  loading: false,
  page: 0,
  pageSize: 10,
  total: 0,
  query: '',
}
export default function librariesReducer(state: TLibrariesState = initialState, action: AnyAction): TLibrariesState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}
