/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from 'redux'

const actions = {
  LOAD_LIBRARY_ELEMENTS: 'libraryElements/LOAD_LIBRARY_ELEMENTS',
  LOAD_NEXT_LIBRARY_ELEMENTS: 'libraryElements/LOAD_NEXT_LIBRARY_ELEMENTS',
  SET_STATE: 'libraryElements/SET_STATE',
  LOAD_STATE: 'libraryElements/LOAD_STATE',
  UPLOAD_ITEM: 'libraryElements/UPLOAD_ITEM',
  GET_STATE: 'libraryElements/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const loadLibraryElements = (query: string): AnyAction => {
  return {
    type: actions.LOAD_LIBRARY_ELEMENTS,
    payload: {
      query,
    },
  }
}

export const loadNextLibraryElements = (): AnyAction => {
  return {
    type: actions.LOAD_NEXT_LIBRARY_ELEMENTS,
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const uploadItem = (contentItemId: string): AnyAction => {
  return {
    type: actions.UPLOAD_ITEM,
    payload: {
      contentItemId,
    },
  }
}
