/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, ReactElement } from 'react'
import { Card, notification, Descriptions, Icon } from 'antd'
import Meta from 'antd/lib/card/Meta'
import TimeAgo from 'timeago-react'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import MimeType from '../../mimetype'
import styles from './styles.module.scss'

interface IProps {
  actions: ReactElement[]
  contentItem: ContentItem
  onLoad?: () => void
  showHover?: boolean
}
const SocialCard: React.FunctionComponent<IProps> = ({ actions, onLoad, contentItem, showHover }: IProps): JSX.Element => {
  const { urls: { thumbnail } = {}, name, created, width, height, mimeType } = contentItem
  const [createdDate, setCreateDate] = useState<Date>()
  const [isLoading, setLoading] = useState<boolean>(!!thumbnail)
  const [isHidden, setHideCard] = useState<boolean>(false)
  const [showImage, setShowImage] = useState<boolean>(!!thumbnail)

  const onImageLoad = (): void => {
    setLoading(false)
    if (created !== undefined) {
      setCreateDate(new Date(created))
    }
    if (onLoad) {
      onLoad()
    }
  }

  const onImageError = (e): void => {
    console.error(e)
    notification.error({
      message: 'Image Load Error',
      description: `Image of Post ${contentItem.id} failed`,
    })
    /* setHideCard(true) */
    setShowImage(false)
    onImageLoad()
  }

  return (
    <>
      {contentItem.mediaType === 'Folder' ? (
        <Card
          actions={actions}
          className={styles.status}
          cover={<Icon type="folder-open" theme="twoTone" style={{ fontSize: '5em', marginTop: '0.5em' }} />}
          hidden={isHidden}
          loading={isLoading}
        >
          <Meta title={name} />
        </Card>
      ) : (
        <Card
          actions={actions}
          className={styles.status}
          cover={showImage ? <img alt={name} onError={onImageError} onLoad={onImageLoad} src={thumbnail} width={100} /> : null}
          hidden={isHidden}
          loading={isLoading}
        >
          <Meta
            title={name}
            description={
              <Descriptions size="small" column={1}>
                <Descriptions.Item label="Width">{width}</Descriptions.Item>
                <Descriptions.Item label="Height">{height}</Descriptions.Item>
                <Descriptions.Item label="Type">
                  <MimeType mimeType={mimeType} />
                </Descriptions.Item>
              </Descriptions>
            }
          />
        </Card>
      )}
    </>
  )
}

export default SocialCard
