/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import contentSourceApi from '~/services/ContentSource'
import actions, { setLoading } from './actions'

function* LOAD_STATE({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  yield put({
    type: actions.SET_STATE,
    payload: {
      contentId: payload.contentId,
      contentItem: undefined,
      items: [],
    },
  })

  try {
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }
    const contentItemResult = yield contentSourceApi.getContentItem(`${payload.libraryId}_${payload.contentId}`, options)

    yield put({
      type: actions.SET_STATE,
      payload: {
        contentItem: contentItemResult.data,
        loading: false,
      },
    })
  } catch (error) {
    // console.log('LOAD_STATE (collections) got error', error)
    yield put({
      type: actions.SET_STATE,
      payload: [],
    })
  }

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_STATE, LOAD_STATE)])
}
