/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { ISagas } from '~/redux/interface'
import contentSourceApi from '~/services/ContentSource'
import actions, { setLoading } from './actions'

function* LOAD_LIBRARIES({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const librariesResult = yield contentSourceApi.getContentItems(1, undefined, payload.query, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      query: payload.query,
      items: librariesResult.data.items,
      page: librariesResult.data.page,
      pageSize: librariesResult.data.pageSize,
      total: librariesResult.data.total,
      loading: false,
    },
  })
}

function* LOAD_NEXT_LIBRARIES({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { query, page } = yield select((state: ISagas) => ({
    query: state.libraries.query,
    page: state.libraries.page,
    pageSize: state.libraries.pageSize,
  }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const librariesResult = yield contentSourceApi.getContentItems(page + 1, undefined, query, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      items: librariesResult.items,
      page: librariesResult.page,
      pageSize: librariesResult.pageSize,
      total: librariesResult.total,
      loading: false,
    },
  })
}

function* LOAD_STATE() {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  // console.log('LOAD_STATE (collections)', accessToken);

  yield put(setLoading(true))

  try {
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }
    const collections = yield contentSourceApi.getContentItems(undefined, undefined, undefined, options)
    // console.log('LOAD_STATE (collections)', collections);
    if (collections) {
      yield put({
        type: actions.SET_STATE,
        payload: collections,
      })
    }
  } catch (error) {
    // console.log('LOAD_STATE (collections) got error', error);
    yield put({
      type: actions.SET_STATE,
      payload: [],
    })
  }

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.LOAD_LIBRARIES, LOAD_LIBRARIES),
    takeEvery(actions.LOAD_NEXT_LIBRARIES, LOAD_NEXT_LIBRARIES),
  ])
}
