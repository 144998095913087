/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Icon } from 'antd'
import AdobePhotoshop from '~/components/icons/AdobePhotoshop'
import AdobeIllustrator from '~/components/icons/AdobeIllustrator'
import AdobeStock from '~/components/icons/AdobeStock'
import AdobeXD from '~/components/icons/AdobeXD'

interface IProps {
  mimeType?: string
}

const MimeType: React.FunctionComponent<IProps> = ({ mimeType }) => {
  console.log('init mimetype')

  function Renderer(): JSX.Element {
    if (mimeType === 'application/photoshop') {
      return (
        <>
          <Icon component={AdobePhotoshop.Icon} /> Adobe Photoshop
        </>
      )
    }
    if (mimeType === 'application/illustrator') {
      return (
        <>
          <Icon component={AdobeIllustrator.Icon} /> Adobe Illustrator
        </>
      )
    }
    if (mimeType === 'application/vnd.adobe.library.link+dcx') {
      return (
        <>
          <Icon component={AdobeStock.Icon} /> Adobe Stock
        </>
      )
    }
    if (mimeType === 'application/adobexd') {
      return (
        <>
          <Icon component={AdobeXD.Icon} /> Adobe XD
        </>
      )
    }
    if (mimeType === 'image/jpeg') {
      return (
        <>
          <Icon type="file-jpg" /> JPEG
        </>
      )
    }
    if (mimeType === 'image/gif') {
      return (
        <>
          <Icon type="file-image" /> GIF
        </>
      )
    }
    if (mimeType === 'image/png') {
      return (
        <>
          <Icon type="file-image" /> PNG
        </>
      )
    }
    if (mimeType === 'image/tiff') {
      return (
        <>
          <Icon type="file-image" /> TIFF
        </>
      )
    }
    if (mimeType === 'video/mp4') {
      return (
        <>
          <Icon type="file-video" /> MP4
        </>
      )
    }
    return <>{mimeType}</>
  }

  return Renderer()
}

export default MimeType
