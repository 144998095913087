/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Button, Row, Col, Divider, Layout, Input, Alert } from 'antd'
import { ISagas } from '~/redux/interface'
import { loadLibraries, loadNextLibraries } from '~/redux/content/actions'
import MediaMasonry from '~/components/media-masonry'
import LibraryElements from './LibraryElements'

const { Footer } = Layout
const { Search } = Input

const Libraries: React.FunctionComponent = () => {
  const librariesState = useSelector((state: ISagas) => state.libraries)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLibraries(''))
  }, [])

  const onLoadNextPage = (): void => {
    dispatch(loadNextLibraries())
  }

  const onSearch = (value): void => {
    console.log(value)
    dispatch(loadLibraries(value))
  }

  return (
    <PageHeader title="Libraries">
      <Search placeholder="Search" onSearch={onSearch} enterButton />
      <Divider />
      <MediaMasonry items={librariesState.items} />
      {librariesState.items !== undefined && librariesState.total > librariesState.items.length ? (
        <Footer>
          <Divider />
          <Row>
            <Col offset={8} span={8} style={{ textAlign: 'center' }}>
              <Button disabled={librariesState.loading} onClick={onLoadNextPage} type="dashed">
                Load more
              </Button>
            </Col>
          </Row>
        </Footer>
      ) : null}
    </PageHeader>
  )
}

export default {
  name: 'Libraries',
  routeProps: {
    path: '/libraries',
    component: Libraries,
  },
  navProps: {
    title: 'Libraries ',
  },
  children: [LibraryElements],
}
