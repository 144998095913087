/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { ISagas } from '~/redux/interface'
import contentSourceApi from '~/services/ContentSource'
import actions, { setLoading } from './actions'

function* LOAD_LIBRARY_ELEMENTS({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const libraryElementsResult = yield contentSourceApi.getContentItems(1, undefined, payload.query, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      query: payload.query,
      items: libraryElementsResult.data.items,
      page: libraryElementsResult.data.page,
      pageSize: libraryElementsResult.data.pageSize,
      total: libraryElementsResult.data.total,
      loading: false,
    },
  })
}

function* LOAD_NEXT_LIBRARY_ELEMENTS({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { query, page } = yield select((state: ISagas) => ({
    query: state.libraryElements.query,
    page: state.libraryElements.page,
    pageSize: state.libraryElements.pageSize,
  }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const libraryElementsResult = yield contentSourceApi.getContentItems(page + 1, undefined, query, options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      items: libraryElementsResult.items,
      page: libraryElementsResult.page,
      pageSize: libraryElementsResult.pageSize,
      total: libraryElementsResult.total,
      loading: false,
    },
  })
}

function* LOAD_STATE({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  // console.log('LOAD_STATE (collections)', accessToken);

  yield put(setLoading(true))

  yield put({
    type: actions.SET_STATE,
    payload: {
      libraryId: payload.libraryId,
      library: undefined,
      items: [],
    },
  })

  try {
    const options = { headers: { Authorization: `Bearer ${accessToken}` } }
    const libraryResult = yield contentSourceApi.getContentItem(payload.libraryId, options)

    yield put({
      type: actions.SET_STATE,
      payload: {
        library: libraryResult.data,
      },
    })

    const libraryElementsResult = yield contentSourceApi.getContentItems(1, payload.libraryId, '', options)

    yield put({
      type: actions.SET_STATE,
      payload: {
        query: '',
        items: libraryElementsResult.data.items,
        page: libraryElementsResult.data.page,
        pageSize: libraryElementsResult.data.pageSize,
        total: libraryElementsResult.data.total,
        loading: false,
      },
    })
  } catch (error) {
    // console.log('LOAD_STATE (collections) got error', error);
    yield put({
      type: actions.SET_STATE,
      payload: [],
    })
  }

  yield put(setLoading(false))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.LOAD_LIBRARY_ELEMENTS, LOAD_LIBRARY_ELEMENTS),
    takeEvery(actions.LOAD_NEXT_LIBRARY_ELEMENTS, LOAD_NEXT_LIBRARY_ELEMENTS),
  ])
}
