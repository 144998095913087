/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react'

function Icon(props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 234" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" fill="#2e000a" d="M10 10h220v213.999H10z" />
      <path d="M0 0v233.999h240.001V0H0zm10 10h220.001v213.999H10V10z" fillRule="evenodd" clipRule="evenodd" fill="#ff4475" />
      <path
        d="M60.99 158.802c-.66-.494-.99-1.154-.99-2.145v-15.84c0-.496.66-.99 1.32-.496 7.425 5.115 16.104 7.426 24.354 7.426 12.045 0 16.995-6.27 16.995-13.365 0-6.27-3.135-11.385-14.685-18.15l-5.94-3.135C65.05 103.527 60.1 93.793 60.1 81.418c0-16.83 11.715-30.36 33.825-30.36 9.735 0 16.96 1.485 21.085 3.795.825.33.99.825.99 1.815v14.85c0 .66-.66 1.154-1.32.66-5.115-3.301-12.67-4.95-20.425-4.95-12.705 0-16.83 7.26-16.83 13.035 0 6.27 3.135 10.89 14.52 17.159l5.28 2.806c18.81 10.396 23.595 20.459 23.595 32.834 0 18.314-13.365 31.02-35.145 31.02-9.735-.001-18.581-1.815-24.685-5.28zm114.51 1.485c0 .826-.33 1.486-1.156 1.816-3.465 1.318-7.26 1.979-12.209 1.979-12.541 0-19.635-6.6-19.635-22.109V97h-9.487c-.825 0-1.013-.33-1.013-1.155V82.57c0-.825.165-1.32 1.155-1.32h9.487c.166-5.775.826-15.84 1.485-18.975 0-1.155.496-1.482 1.154-1.648l14.852-1.979c.66-.165 1.154-.003 1.154.822-.33 4.785-.494 12.87-.66 21.78h14.467c.826 0 1.156.33 1.156 1.155V96.01c0 .825-.496.99-1.156.99H160.5v41.91c0 6.766 2.311 9.828 8.414 9.828 1.981 0 3.615-.166 5.596-.66.494-.166.99.164.99.99v11.219z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#ff4475"
      />
    </svg>
  )
}

export default { Icon }
