/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Button, Row, Col, Layout, Input } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import styles from './styles.module.scss'

const { Footer } = Layout
const { Search } = Input

const Libraries: React.FunctionComponent = () => {
  const oidcState = useSelector((state: TDefaultSagas) => state.oidc)
  const history = useHistory()

  const onClose = (): void => {
    history.goBack()
  }

  return (
    <PageHeader title="Profile">
      <Row className={styles.profileAttribute}>
        <Col span={5}>First name</Col>
        <Col>{oidcState.user?.profile.given_name}</Col>
      </Row>
      <Row className={styles.profileAttribute}>
        <Col span={5}>Family name</Col>
        <Col>{oidcState.user?.profile.family_name}</Col>
      </Row>
      <Row className={styles.profileAttribute}>
        <Col span={5}>Email</Col>
        <Col>{oidcState.user?.profile.email}</Col>
      </Row>
      <Row className={styles.profileAttribute}>
        <Button type="primary" onClick={onClose}>
          Close
        </Button>
      </Row>
    </PageHeader>
  )
}

export default {
  name: 'Profile',
  routeProps: {
    path: '/profile',
    component: Libraries,
  },
  navProps: {
    title: 'Profile ',
  },
}
