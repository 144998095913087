/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react'
import videojs from 'video.js'

// Styles
import 'video.js/dist/video-js.css'

interface VideoPlayerProps {
  options: videojs.PlayerOptions
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ options }) => {
  const videoNode: React.RefObject<HTMLVideoElement> = useRef<HTMLVideoElement>(null)
  const player = React.useRef<videojs.Player>()

  React.useEffect(() => {
    player.current = videojs(videoNode.current, {
      ...initialOptions,
      ...options,
    })
    return (): void => {
      if (player.current) {
        player.current.dispose()
      }
    }
  }, [options])

  return <video ref={videoNode} className="video-js vjs-big-play-centered" />
}

export default VideoPlayer
