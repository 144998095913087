/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Button, Row, Col, Divider, Layout, Input, Breadcrumb } from 'antd'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { TBreadcrumbItemProps } from '@antegma/accallio-cloud-react-components/lib/app/util/appRoutes'
import { ISagas } from '~/redux/interface'
import actions, { loadLibraryElements, loadNextLibraryElements } from '~/redux/content/items/actions'
import MediaMasonry from '~/components/media-masonry'
import LibraryElementDetails from './LibraryElementDetails'

const { Footer } = Layout
const { Search } = Input

const LibaryElements: React.FunctionComponent = () => {
  const route = useRouteMatch<{ libraryId: string }>()
  const dispatch = useDispatch()
  const oidc = useSelector((state: TDefaultSagas) => state.oidc)
  const libraryElementsState = useSelector((state: ISagas) => state.libraryElements)

  const {
    params: { libraryId },
  } = route

  useEffect(() => {
    dispatch({
      type: actions.LOAD_STATE,
      payload: {
        libraryId,
      },
    })
  }, [dispatch])

  const onLoadNextPage = (): void => {
    dispatch(loadNextLibraryElements())
  }

  const onSearch = (value): void => {
    console.log(value)
    dispatch(loadLibraryElements(value))
  }

  return (
    <PageHeader title={libraryElementsState.library ? libraryElementsState.library.name : null}>
      <Search placeholder="Search" onSearch={onSearch} enterButton />
      <Divider />
      <MediaMasonry items={libraryElementsState.items} />
      {libraryElementsState.total > libraryElementsState.items.length ? (
        <Footer>
          <Divider />
          <Row>
            <Col offset={8} span={8} style={{ textAlign: 'center' }}>
              <Button disabled={libraryElementsState.loading} onClick={onLoadNextPage} type="dashed">
                Load more
              </Button>
            </Col>
          </Row>
        </Footer>
      ) : null}
    </PageHeader>
  )
}

const LibaryElementsBreadCrumbItem: React.FunctionComponent<TBreadcrumbItemProps> = (props: TBreadcrumbItemProps) => {
  const { page, active } = props
  const route = useRouteMatch<{ libraryId: string }>()
  const dispatch = useDispatch()
  const libraryElementsState = useSelector((state: ISagas) => state.libraryElements)

  const {
    params: { libraryId },
  } = route

  useEffect(() => {
    dispatch({
      type: actions.GET_STATE,
    })
  }, [dispatch])

  const getBreadcrumbPath = (path: string): string => {
    return path.replace(':libraryId', libraryElementsState.libraryId)
  }

  return active ? (
    <Breadcrumb.Item key="breadcrumb-ContentDetailView">{libraryElementsState.library?.name}</Breadcrumb.Item>
  ) : (
    <Breadcrumb.Item key="breadcrumb-ContentDetailView">
      <Link to={getBreadcrumbPath(page.routeProps.path as string)}>{libraryElementsState.library?.name}</Link>
    </Breadcrumb.Item>
  )
}

export default {
  name: 'LibaryElements',
  routeProps: {
    path: '/:libraryId/elements',
    component: LibaryElements,
  },
  navProps: {
    title: 'Library Elements ',
  },
  breadcrumbProps: {
    component: LibaryElementsBreadCrumbItem,
  },
  children: [LibraryElementDetails],
}
