/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AnyAction } from 'redux'

const actions = {
  LOAD_LIBRARIES: 'libraries/LOAD_LIBRARIES',
  LOAD_NEXT_LIBRARIES: 'libraries/LOAD_NEXT_LIBRARIES',
  SET_STATE: 'libraries/SET_STATE',
  LOAD_STATE: 'libraries/LOAD_STATE',
  GET_STATE: 'libraries/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const loadLibraries = (query: string): AnyAction => {
  return {
    type: actions.LOAD_LIBRARIES,
    payload: {
      query,
    },
  }
}

export const loadNextLibraries = (): AnyAction => {
  return {
    type: actions.LOAD_NEXT_LIBRARIES,
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}
