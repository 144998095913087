/* eslint-disable @typescript-eslint/no-explicit-any */

import { AnyAction } from 'redux'

const actions = {
  SET_STATE: 'libraryElementDetails/SET_STATE',
  LOAD_STATE: 'libraryElementDetails/LOAD_STATE',
  UPLOAD_ITEM: 'libraryElementDetails/UPLOAD_ITEM',
  GET_STATE: 'libraryElementDetails/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const loadState = (libraryId: string, contentItemId: string): AnyAction => {
  return {
    type: actions.LOAD_STATE,
    payload: {
      libraryId,
      contentItemId,
    },
  }
}

export const uploadItem = (contentItemId: string): AnyAction => {
  return {
    type: actions.UPLOAD_ITEM,
    payload: {
      contentItemId,
    },
  }
}
