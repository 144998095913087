/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ContentItem } from '@antegma/accallio-cloud-hootsuite-application-api-javacript-sdk'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import { Button } from 'antd'
import contentSourceApi from '~/services/ContentSource'
import tokenApi from '~/services/Token'
import SocialCard from '~/components/social-media/socialcard'

type TCardProps = {
  contentItem: ContentItem
}

const MediaCardView: React.FunctionComponent<TCardProps> = ({ contentItem }) => {
  const oidc = useSelector((state: TDefaultSagas) => state.oidc)
  const ctx = useContext(ApplicationContext)
  const history = useHistory()

  const options = { headers: { Authorization: `Bearer ${oidc.user?.access_token}` } }

  let hootsuiteUserId: string
  if (ctx.extraQueryParams !== undefined) {
    hootsuiteUserId = ctx.extraQueryParams.hootsuiteUserId
  }

  const openComposer = (): void => {
    let contentItemId = ''
    if (contentItem.id !== undefined) {
      contentItemId = contentItem.id
      if (contentItem.mediaType === 'Video') {
        contentSourceApi.uploadContentItemToHootsuite(contentItemId).then((response) => {
          let hootsuiteMediaId = response.data

          let dateTime = Date.now()
          let timestamp = Math.floor(dateTime / 1000)
          let userId = hootsuiteUserId
          console.log(`hootsuite user id for attach to media: ${hootsuiteUserId}`)
          let input = `${userId}${timestamp}${hootsuiteMediaId}`

          tokenApi.generateToken(input).then((tokenResponse) => {
            let token = tokenResponse.data

            window['hsp']['attachMediaV2']({
              mediaId: hootsuiteMediaId,
              timestamp,
              token,
            })
          })
        })
      } else if (contentItem.mediaType === 'Image') {
        const directUpload = false
        let url = contentItem.urls?.original
        let dateTime = Date.now()
        let timestamp = Math.floor(dateTime / 1000)
        console.log(`hootsuite user id for attach file to message: ${hootsuiteUserId}`)
        let input = `${hootsuiteUserId}${timestamp}${url}`

        tokenApi.generateToken(input).then((tokenResponse) => {
          let token = tokenResponse.data
          let fileNameParts = contentItem.name?.split('.')
          let extension = ''
          if (fileNameParts !== undefined) {
            extension = fileNameParts[fileNameParts.length - 1]
          }

          window['hsp']['attachFileToMessageV2']({
            url,
            name: contentItem.name,
            extension,
            timestamp,
            token,
          })
        })
      }
    }
  }
  const openDetail = (): void => {
    console.log(contentItem.id)
    history.push(`/contents/${contentItem.id}`)
  }

  const onLoad = (): void => {
    /* console.log('load'); */
  }

  return (
    <>
      {contentItem.mediaType === 'Folder' ? (
        <Link to={`/libraries/${contentItem.id}/elements`}>
          <SocialCard actions={[]} key={contentItem.id} contentItem={contentItem} onLoad={onLoad} />
        </Link>
      ) : (
        <Link to={`/libraries/${contentItem.id?.split('_')[0]}/elements/${contentItem.id?.split('_')[1]}`}>
          <SocialCard
            key={contentItem.id}
            actions={[
              <Button type="link" icon="upload" onClick={openComposer}>
                Open Details
              </Button>,
            ]}
            contentItem={contentItem}
            onLoad={onLoad}
          />
        </Link>
      )}
    </>
  )
}

export default MediaCardView
